<template>
  <base-web-page>
    <template v-slot:main>
        <el-main style="margin:0 auto;">
          <el-card shadow="never" style="width:600px;">
              <div slot="header">
                  <h1>Create Account</h1>
              </div>
              <div style="text-align:left;">
                  <div style="font-size:14px;font-weight:bold;">Invite Code (Not Required)</div>
                  <div style="margin-top:20px;"><el-input v-model="registerForm.invite_code" placeholder="" type="email"></el-input></div>
                  <div style="margin-top:20px;font-size:14px;font-weight:bold;"><span style="color:red">*</span>Name</div>
                  <div style="margin-top:20px;"><el-input v-model="registerForm.name"></el-input></div>
                  <div style="margin-top:20px;font-size:14px;font-weight:bold;"><span style="color:red">*</span>Email</div>
                  <div style="margin-top:20px;"><el-input v-model="registerForm.email"></el-input></div>
                  <div style="margin-top:20px;font-size:14px;font-weight:bold;"><span style="color:red">*</span>Contact No</div>
                  <div style="margin-top:20px;"><el-input v-model="registerForm.contact_no"><template slot="prepend">+65</template></el-input></div>
                  <div style="margin-top:20px;font-size:14px;font-weight:bold;"><span style="color:red">*</span>Company Name</div>
                  <div style="margin-top:20px;"><el-input v-model="registerForm.company_name"></el-input></div>
                  <div style="margin-top:20px;;font-size:14px;font-weight:bold;"><span style="color:red">*</span>Location</div>
                  <div style="margin-top:20px;">
                      <el-select v-model="registerForm.location_id" placeholder="choose location">
                        <el-option v-for="item in locations" :key="item.ID" :label="item.name" :value="item.ID"></el-option>
                      </el-select>
                  </div>
                  <div style="margin-top:20px;;font-size:14px;font-weight:bold;"><span style="color:red">*</span>Password</div>
                  <div style="margin-top:20px;"><el-input placeholder="" v-model="registerForm.password" show-password></el-input></div>
                  <div style="margin-top:20px;display:-webkit-box;">
                    <el-checkbox v-model="agreeTermsOfService"></el-checkbox>
                    <div style="font-size:14px;margin-left:8px;">I HAVE READ THESE TERMS AND CONDITIONS AND AGREE TO ALL OF THE PROVISIONS CONTAINED ABOVE AND ANY REVISION 
                      THE SAME HEREAFTER. BY CLICKING THE "REGISTER NOW" BUTTON OR ANY SIMILAR ACTION BELOW, I UNDERSTAND THAT I AM CREATING A DIGITAL SIGNATURE, WHICH I INTEND 
                      TO HAVE THE SAME FORCE AND EFFECT AS IF I HAD SIGNED MY NAME MANUALLY.</div>
                  </div>
                  <div style="margin-top:10px;text-align:right;">
                    <el-link href="/pages/terms-of-service" style="color:#409EFF;">Terms of Service</el-link>
                  </div>
                  <div style="margin-top:20px;"><el-button type="danger" style="width:100%" @click="submitRegister">Register Now</el-button></div>
              </div>
          </el-card>
      </el-main>
    </template>
  </base-web-page>
</template>

<script>
import BaseWebPage from '../../components/base/BaseWebPage.vue'
import ModelRegister from '../viewmodel/ModelRegister.vue'

export default {
    extends: ModelRegister,
    name: 'register',
    components: {
        BaseWebPage
    }
}
</script>

<style scoped>
.el-card{
    border: 1px solid #fff !important;
}
</style>