<template>
  <div></div>
</template>


<script>
import {mapState, mapActions} from 'vuex'

export default {
    name: 'register',
    data(){
        return {
            registerForm: {
                invite_code: '',
                name: '',
                contact_no: '',
                company_name: '',
                location_id: '',
                location: null,
                email: '',
                password: ''
            },
            agreeTermsOfService: true
        }
    },
    computed: {
        ...mapState('util', [
            'locations'
        ]),
    },
    created(){
        this.getLocations()
    },
    methods:{
        ...mapActions('util', [
            'getLocations'
        ]),
        ...mapActions('client', [
            'register'
        ]),
        isEmail(str) {
            var reg = /^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/
            return  reg.test(str);
        },
        submitRegister(){
            if(!this.agreeTermsOfService) {
                this.$message.error('only agree the terms of service can reigster now')
                return
            }
            if(this.registerForm.name.length === 0 ||
            this.registerForm.contact_no.length != 8 ||
            this.registerForm.company_name.length === 0 ||
            this.registerForm.email.length === 0 ||
            this.registerForm.password.length === 0) {
                this.$message.error('please fill all columns with *')
                return
            }
            if(!this.isEmail(this.registerForm.email)){
                this.$message({
                    message: 'email format error',
                    type: 'error'
                })
                return
            }
            if(this.registerForm.password.length < 6){
                this.$message({
                    message: 'password must be more than 6 characters',
                    type: 'error'
                })
                return
            }
            let sha256 = require('js-sha256')
            this.registerForm.password = sha256(this.registerForm.password)
            this.register(this.registerForm).then(()=>{
                this.$message({
                    message: 'register successfully',
                    type: 'success'
                })
                 window.location.href = '/'
            }).catch((result)=>{
                console.log('11')
                console.log(result)
                console.log(result.message)
                if (result.message === 'USED_CODE') {
                    this.$message.error('Invite Code has been used!')
                } else {
                    this.$message.error('email or mobile is already exist, please change your email and try again')
                }
                this.registerForm.password = ''
            })
        }
    }
}
</script>

<style scoped>